export function removeSlashes(str) {
  let newString = str;

  if (newString.startsWith("/")) {
    newString = newString.substring(1);
  }

  if (newString.endsWith("/")) {
    newString = newString.substring(0, newString.length - 1);
  }

  return newString;
}

export function slugify(str) {
  let newString = str;

  newString = newString.replaceAll(" ", "-");
  newString = newString.replaceAll("/", "-");
  newString = newString.toLowerCase();

  return newString;
}

export function parseSource(request) {
  try {
    const symbolKeys = Object.getOwnPropertySymbols(request);

    const stateSymbol = symbolKeys.find(
      (sym) => sym.toString() === "Symbol(state)"
    );

    const requestState = stateSymbol ? request[stateSymbol] : null;

    const source = JSON.parse(requestState.body.source);

    if (requestState && source) {
      return source;
    } else {
      throw "no state symbol";
    }
  } catch (error) {
    console.log("error with parsing source", error);
    return {};
  }
}

export function formatDateForGoogleSheet(date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // DST / Offset?
  const isEasternDaylightTime = isInEasternDaylightTime(date);
  const estOffset = isEasternDaylightTime ? -4 : -5;

  // Get the day, month, and year
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Add ordinal suffix to the day
  const ordinalSuffixes = ["th", "st", "nd", "rd"];
  const v = day % 100;
  const ordinalSuffix =
    ordinalSuffixes[(v - 20) % 10] || ordinalSuffixes[v] || ordinalSuffixes[0];

  // Get hours and minutes for 12-hour format, manually adjusted to EST
  let hours = date.getUTCHours() + estOffset;
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes to always be two digits
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Combine everything into a formatted string
  return `${month} ${day}${ordinalSuffix}, ${year} ${hours}:${formattedMinutes}${ampm}`;
}

export function isInEasternDaylightTime(date) {
  // Get year from the provided date
  const year = date.getFullYear();

  // Function to get the Nth day of a specific month and weekday
  function getNthDayOfMonth(nth, day, month, year) {
    let counter = 0;
    let date = new Date(year, month, 1);
    while (true) {
      if (date.getDay() === day) {
        counter += 1;
        if (counter === nth) {
          return date;
        }
      }
      date.setDate(date.getDate() + 1);
    }
  }

  // DST starts on the second Sunday in March
  const dstStart = getNthDayOfMonth(2, 0, 2, year); // 0 is Sunday, 2 is March

  // DST ends on the first Sunday in November
  const dstEnd = getNthDayOfMonth(1, 0, 10, year); // 10 is November

  // Check if current date is within the DST period
  return date >= dstStart && date < dstEnd;
}
