import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// import AOS from "aos";
// import "aos/dist/aos.css";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";

import Layout from "../components/Layout";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FloatingThingy from "../components/FloatingThingy";
import CopyBlock from "../components/CopyBlock";
import SocialShare from "../components/SocialShare";

import * as styles from "../styles/Post.module.css";

import "../styles/tokens.css";
import "../styles/global.css";
import "../styles/section.css";
import { slugify } from "../utils/helpers";

const Post = ({ data, location }) => {
  useEffect(() => {
    // AOS.init({ duration: 600 });
  }, []);

  const { company, navigation } = data.contentfulPage;

  const {
    body,
    excerpt,
    image,
    publishDate,
    title,
    seo,
    postSlug,
    pillar,
    category,
    floatingCta,
  } = data.contentfulPost;

  const { edges: allPosts } = data.allContentfulPost;
  let sameCategoryPosts = [];
  let samePillarPosts = [];
  let allOtherPosts = [];

  allPosts.forEach(({ node: post }, index) => {
    if (index < 3) {
      if (post.category === category) {
        sameCategoryPosts.push(post);
      } else if (post.pillar === pillar) {
        samePillarPosts.push(post);
      } else {
        allOtherPosts.push(post);
      }
    }
  });

  const relatedPosts = [
    ...sameCategoryPosts,
    ...samePillarPosts,
    ...allOtherPosts,
  ];

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={styles.blogParagraph}>{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className={styles.blogHeader}>{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className={styles.blogHeader}>{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className={styles.blogHeader}>{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className={styles.blogHeader}>{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className={styles.blogHeader}>{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className={styles.blogHeader}>{children}</h6>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <div className={styles.blogBlockquote}>
          <blockquote>{children}</blockquote>
        </div>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="blog-ordered-list">{children}</ol>
      ),
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className="blog-unordered-list">{children}</ul>;
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className="blog-list-item">{children}</li>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <GatsbyImage
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.title}
          />
        );
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {},
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri.charAt(0) === "/") {
          return (
            <Link to={node.data.uri} className="">
              {node.content[0].value}
            </Link>
          );
        } else {
          return (
            <a
              href={node.data.uri}
              className=""
              target="_blank"
              rel="noreferrer"
            >
              {node.content[0].value}
            </a>
          );
        }
      },
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  const post = renderRichText(body, options);
  const [scroll, setScroll] = useState(false);

  const PostCard = ({ post }) => {
    const { image, slug, title } = post;

    return (
      <>
        <Link className={styles.postCardWrapper} to={slug} title={title}>
          <div className={styles.postCardContent}>
            {image && (
              <GatsbyImage
                image={image.file.gatsbyImageData}
                alt={title}
                className={styles.backgroundImage}
              />
            )}
          </div>
          <div className={styles.postCardTitle}>
            <h6>{title}</h6>
          </div>
        </Link>
      </>
    );
  };

  return (
    <Layout
      slug={postSlug}
      seo={seo}
      location={location}
      scroll={scroll}
      styles={{ position: "relative" }}
      company={company}
    >
      <a href="#main" title="Skip to main content" className="sr-only">
        Skip to main content
      </a>
      <Navigation
        company={company}
        navigation={navigation}
        slug={postSlug}
        setScroll={setScroll}
        enableDarkNav={true}
      />
      <div id="main">
        <section className="section">
          <div className="container">
            <div className={styles.title}>
              <div>
                <h1>{title}</h1>
                <p>
                  <Link to={`/blog/${slugify(pillar)}/`}>{pillar}</Link> |{" "}
                  <Link to={`/blog/${slugify(pillar)}/${slugify(category)}/`}>
                    {category}
                  </Link>
                </p>
              </div>
              {publishDate && (
                <div>
                  <p>{publishDate}</p>
                </div>
              )}
            </div>
            <div className={styles.titleSocial}>
              <SocialShare
                url={seo.ogUrl}
                title={`${title} | The Moonello Blog`}
                shareText={"Share this on:"}
              />
            </div>

            <div className={styles.post}>
              <div
                dangerouslySetInnerHTML={{
                  __html: excerpt.childMarkdownRemark.html,
                }}
                className={styles.excerpt}
              />
              {image && (
                <div className={styles.featuredImageContainer}>
                  <GatsbyImage
                    image={image.file.gatsbyImageData}
                    alt={title}
                    className={styles.featuredImage}
                    loading="eager"
                  />
                </div>
              )}
              {post}
            </div>
            <div className={styles.endSocial}>
              <SocialShare
                url={seo.ogUrl}
                title={`${title} | The Moonello Blog`}
                shareText={"Share this on:"}
              />
            </div>
            {relatedPosts && (
              <div className={styles.relatedPostsWrapper}>
                <h4>Related Posts</h4>
                <div className={styles.relatedPosts}>
                  {relatedPosts.map((post) => {
                    return <PostCard post={post} key={post.id} />;
                  })}
                </div>
              </div>
            )}
          </div>
        </section>
        <Footer
          navigation={navigation}
          company={company}
          backgroundColor={"white"}
        />
      </div>
      {floatingCta && (
        <FloatingThingy variant={pillar ? "panel" : "default"}>
          <CopyBlock props={floatingCta} />
        </FloatingThingy>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            sys {
              type
            }
            gatsbyImageData(layout: CONSTRAINED)
            file {
              url
            }
          }
        }
      }
      author
      pillar
      category
      publishDate(formatString: "MMMM DD, YYYY")
      postSlug: slug
      title
      floatingCta {
        id
        icon {
          name
          isBrand
          size
          color
          hasBackground
          backgroundColor
        }
        centerAlignIcon
        centerAlignContent
        textColor
        headline
        headlineLevel
        enableHeadlineBottomBorder
        copy {
          raw
        }
        name
        ctaButton {
          id
          title
          to
          opensInNewWindow
          styleAsButton
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      seo {
        pageTitle
        description
        robots
        twitterTitle
        twitterCreator
        twitterImage {
          alt
          file {
            title
            description
            url
          }
        }
        ogTitle
        ogUrl
        ogImage {
          alt
          file {
            title
            description
            url
          }
        }
      }
      excerpt {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          description
          url
          gatsbyImageData
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    allContentfulPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          slug
          title
          id
          pillar
          category
          formattedDate: publishDate(formatString: "MMMM DD YYYY")
          publishDate
          image {
            file {
              description
              url
              gatsbyImageData
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
    contentfulPage(slug: { eq: "/blog/" }) {
      name
      slug
      enableDarkNav
      noIndex
      pageTitle
      pageDescription
      shareImage {
        gatsbyImageData(width: 1500)
        description
        url
      }
      company {
        id
        name
        phone
        address {
          childMarkdownRemark {
            html
          }
        }
        email
        copyright
        socialLinks {
          platform
          icon {
            name
            isBrand
            size
          }
          link
        }
        footerNewsletter {
          id
          title
          type
          variant
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            alt
            file {
              gatsbyImageData(width: 1000)
              description
              url
              publicUrl
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          code
          buttonText
          redirectTo {
            slug
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
      navigation {
        id
        name
        darkLogo {
          alt
          file {
            gatsbyImageData(width: 250)
            description
            url
            publicUrl
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        lightLogo {
          alt
          file {
            gatsbyImageData(width: 250)
            description
            url
            publicUrl
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        pages {
          navLabel
          slug
          pageTitle
          pageDescription
          shareImage {
            gatsbyImageData(width: 1000)
          }
          navSubPages {
            navLabel
            slug
          }
        }
        callToAction {
          navLabel
          slug
        }
      }
      modules {
        id
        name
        size
        isContainerFluid
        backgroundColor
        sectionId
        extraClasses
        headline
        headlineSize
        headlineHorizontalAlign
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        module {
          ... on ContentfulImage {
            id
            alt
            file {
              gatsbyImageData(width: 2000)
              description
              url
              publicUrl
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulMinorCta {
            name
            headline
            backgroundColor
            copy {
              childMarkdownRemark {
                html
              }
            }
            button {
              id
              title
              to
              opensInNewWindow
              styleAsButton
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulVideo {
            name
            videoTitle
            youTubeVideoId
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBillboard {
            contentHorizontalAlign
            contentVerticalAlign
            contentWidth
            backgroundOverlayColor
            backgroundImage {
              gatsbyImageData(width: 2000)
              description
              url
              publicUrl
              file {
                url
              }
            }
            copy {
              id
              icon {
                name
                isBrand
                size
                color
                hasBackground
                backgroundColor
              }
              centerAlignIcon
              centerAlignContent
              textColor
              headline
              headlineLevel
              enableHeadlineBottomBorder
              copy {
                raw
              }
              name
              ctaButton {
                id
                title
                to
                opensInNewWindow
                styleAsButton
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            logoRail {
              id
              name
              howManyLogosInARow
              logos {
                alt
                file {
                  gatsbyImageData(width: 1000)
                  description
                  url
                  publicUrl
                }
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulPageHeader {
            id
            name
            backgroundImage {
              file {
                gatsbyImageData(width: 2000)
                description
                url
                publicUrl
                file {
                  url
                }
              }
            }
            backgroundColor
            headline
            subHeadline
            details {
              childMarkdownRemark {
                html
              }
            }
            callsToActions {
              title
              to
              styleAsButton
            }
            textAlign
            benefit1
            benefit2
            benefit3
            benefit4
            pageHeaderImage {
              file {
                gatsbyImageData(width: 2000, height: 750)
                description
                url
                publicUrl
                file {
                  url
                }
              }
            }
            introCopyBackgroundColor
            introCopyA {
              childMarkdownRemark {
                html
              }
            }
            enableLargeIntroCopyA
            introCopyB {
              childMarkdownRemark {
                html
              }
            }
            enableLargeIntroCopyB
            minimizePadding
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulAccordion {
            id
            name
            items {
              heading
              details {
                id
                copy {
                  raw
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulForm {
            id
            name
            darkTheme
            type
            headline
            headlineLevel
            instructions
            defaultSelectedService
            redirectTo {
              slug
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulHeadlineBanners {
            id
            background
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            name
            banners {
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              name
              headline
              image {
                file {
                  gatsbyImageData(width: 2000)
                  description
                  url
                  publicUrl
                  file {
                    url
                  }
                }
              }
              textAlignment
              destination
            }
          }
          ... on ContentfulHero {
            id
            name
            backgroundImage {
              gatsbyImageData(width: 3000)
              description
              url
              publicUrl
            }
            backgroundOverlay
            contentHorizontalAlign
            headline
            subHeadline
            heroCopy: copy
            cta {
              title
              to
              styleAsButton
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulProjectCards {
            id
            name
            cards {
              backgroundImage {
                file {
                  gatsbyImageData(width: 2000)
                  description
                  url
                  publicUrl
                  file {
                    url
                  }
                }
              }
              logo {
                file {
                  gatsbyImageData(width: 2000)
                  description
                  url
                  publicUrl
                  file {
                    url
                  }
                }
              }
              headline
              projectDetails {
                childMarkdownRemark {
                  html
                }
              }
              stat1
              stat2
              stat3
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulSplitContent {
            id
            name
            layout
            backgroundColor
            verticalAlign
            contentLeft {
              components {
                ... on ContentfulVideo {
                  name
                  videoTitle
                  youTubeVideoId
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulImage {
                  id
                  alt
                  file {
                    gatsbyImageData(width: 2000)
                    description
                    url
                    publicUrl
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulCopyBlock {
                  id
                  icon {
                    name
                    isBrand
                    size
                    color
                    hasBackground
                    backgroundColor
                  }
                  centerAlignIcon
                  centerAlignContent
                  textColor
                  headline
                  headlineLevel
                  enableHeadlineBottomBorder
                  ctaButton {
                    id
                    title
                    to
                    opensInNewWindow
                    styleAsButton
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                  }
                  copy {
                    raw
                  }
                  name
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
            }
            contentRight {
              components {
                ... on ContentfulVideo {
                  name
                  videoTitle
                  youTubeVideoId
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulImage {
                  id
                  alt
                  file {
                    gatsbyImageData(width: 2000)
                    description
                    url
                    publicUrl
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulForm {
                  id
                  name
                  type
                  headline
                  headlineLevel
                  instructions
                  redirectTo {
                    slug
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulTiles {
                  id
                  name
                  headline
                  variant
                  background
                  tiles {
                    title
                    body {
                      body
                    }
                    destination

                    iconName
                    iconSource
                    image {
                      alt
                      file {
                        gatsbyImageData(width: 1000)
                        description
                        url
                        publicUrl
                      }
                    }
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulCopyBlock {
                  id
                  icon {
                    name
                    isBrand
                    size
                    color
                    hasBackground
                    backgroundColor
                  }
                  centerAlignIcon
                  centerAlignContent
                  textColor
                  headline
                  headlineLevel
                  ctaButton {
                    id
                    title
                    to
                    opensInNewWindow
                    styleAsButton
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                  }
                  enableHeadlineBottomBorder
                  copy {
                    raw
                  }
                  name
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulLink {
                  id
                  title
                  to
                  opensInNewWindow
                  styleAsButton
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCards {
            id
            name
            background
            cardBackground
            cards {
              headline
              copy
              to
              opensInNewWindow
              backgroundColor
              icon {
                name
                isBrand
                size
                hasBackground
                backgroundColor
              }

              cta {
                title
                to
                opensInNewWindow
                styleAsButton
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCta {
            id
            name
            headline
            subHeadline
            action {
              title
              to
              opensInNewWindow
              styleAsButton
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulTiles {
            id
            name
            headline
            variant
            background
            tiles {
              title
              body {
                body
              }
              destination
              image {
                alt
                file {
                  gatsbyImageData(width: 1000)
                  description
                  url
                  publicUrl
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulTextGrid {
            id
            name
            background
            howManyCopyBlocksInARow
            style
            textCards {
              backgroundColor
              icon {
                name
                isBrand
                size
                hasBackground
                backgroundColor
              }
              copyBlock {
                icon {
                  name
                  isBrand
                  size
                  color
                  hasBackground
                  backgroundColor
                }
                centerAlignIcon
                centerAlignContent
                textColor
                headline
                headlineLevel
                enableHeadlineBottomBorder
                ctaButton {
                  id
                  title
                  to
                  opensInNewWindow
                  styleAsButton
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                copy {
                  raw
                }
              }
              headline
              copy
              cta {
                title
                to
                opensInNewWindow
                styleAsButton
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCarousel {
            items {
              textColor
              testimonial {
                testimonial
              }
              authorName
              authorTitle
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            name
            autoPlay
            dynamicHeight
            infiniteLoop
            interval
            showArrows
            showIndicators
            showStatus
            showThumbs
            stopOnHover
            swipeable
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            transitionTime
            useKeyboardArrows
          }
          ... on ContentfulLeadMagnet {
            id
            title
            type
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              alt
              file {
                gatsbyImageData(width: 1000)
                description
                url
                publicUrl
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            buttonText
            code
            redirectTo {
              slug
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCopyBlock {
            id
            icon {
              name
              isBrand
              size
              color
              hasBackground
              backgroundColor
            }
            centerAlignIcon
            centerAlignContent
            textColor
            headline
            headlineLevel
            enableHeadlineBottomBorder
            ctaButton {
              id
              title
              to
              opensInNewWindow
              styleAsButton
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            copy {
              raw
            }
            name
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulLogoRail {
            id
            name
            howManyLogosInARow
            logos {
              alt
              file {
                gatsbyImageData(width: 1000)
                description
                url
                publicUrl
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Post;
