// extracted by mini-css-extract-plugin
export var backgroundImage = "Post-module--backgroundImage--84a84";
export var blogHeader = "Post-module--blogHeader--62e32";
export var blogParagraph = "Post-module--blogParagraph--6b735";
export var endSocial = "Post-module--endSocial--84261";
export var excerpt = "Post-module--excerpt--5ee4d";
export var featuredImage = "Post-module--featuredImage--bb697";
export var featuredImageContainer = "Post-module--featuredImageContainer--82e4d";
export var post = "Post-module--post--83824";
export var postCardContent = "Post-module--postCardContent--c7f45";
export var postCardTitle = "Post-module--postCardTitle--f5048";
export var postCardWrapper = "Post-module--postCardWrapper--3b6d8";
export var relatedPosts = "Post-module--relatedPosts--d0869";
export var relatedPostsWrapper = "Post-module--relatedPostsWrapper--84e60";
export var title = "Post-module--title--434c0";
export var titleSocial = "Post-module--titleSocial--10918";