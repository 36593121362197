import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import * as styles from "./SocialShare.module.css";

import Icon from "../Icon";

const SocialShare = ({ url, title, shareText }) => {
  return (
    <div className={styles.socialShare}>
      {shareText && <span>{shareText}</span>}
      <div className={styles.socialButtons}>
        <FacebookShareButton url={url} quote={title}>
          <Icon
            props={{
              size: "xl",
              isBrand: true,
              color: "brightBlue",
              name: "fa-facebook",
            }}
          />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={title}>
          <Icon
            props={{
              name: "fa-x-twitter",
              color: "brightBlue",
              size: "xl",
              isBrand: true,
            }}
          />
        </TwitterShareButton>
        <LinkedinShareButton url={url} title={title} summary={title}>
          <Icon
            props={{
              name: "fa-linkedin",
              color: "brightBlue",
              size: "xl",
              isBrand: true,
            }}
          />
        </LinkedinShareButton>
        <EmailShareButton url={url} subject={title}>
          <Icon
            props={{
              name: "fa-envelope",
              color: "brightBlue",
              size: "xl",
            }}
          />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
